import axios from "axios"
import {withTokenReissue} from "./withTokenReissue";

const prefix = `${process.env.REACT_APP_API_SERVER_HOST}/admin/marks`

// 인증마크 전체 조회
export const getList = async (searchTerm, searchBy, filterStatus, sortDirection, page, size, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.get(`${prefix}`, {
                params: {
                    searchTerm: searchTerm,
                    searchBy: searchBy,
                    filterStatus: filterStatus,
                    direction: sortDirection,
                    page: page,
                    size: size
                },
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('certification mark getList error:', error)
        throw error
    }
}

// 진행 중인 인증마크 전체 조회
export const getProgressedList = async (searchTerm, searchBy, filterStatus, sortDirection, page, size, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.get(`${prefix}/progressed`, {
                params: {
                    searchTerm: searchTerm,
                    searchBy: searchBy,
                    filterStatus: filterStatus,
                    direction: sortDirection,
                    page: page,
                    size: size
                },
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('progressed Certification Mark getList error:', error)
        throw error
    }
}

// 인증마크 상세 조회
export const getOne = async (mid, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.get(`${prefix}/${mid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('get Certification Mark error:', error)
        throw error
    }
}

// 검사 업체 등록
export const assignInspection = async (inspectionObj, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.post(`${prefix}/inspection`, inspectionObj, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('assign inspection error:', error)
        throw error
    }
}

// 검사 시간 등록
export const setMeetingTime = async (meetingTimeObj, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.post(`${prefix}/time`, meetingTimeObj, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('set meeting time error:', error)
        throw error
    }
}

// 인증마크 정보 업데이트
export const updateMark = async (markObj, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.patch(`${prefix}`, markObj, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('update mark error:', error)
        throw error
    }
}

// 인증마크 부여
export const registerMark = async (mid, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.post(`${prefix}/${mid}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('register mark error:', error)
        throw error
    }
}

// 인증마크 반려
export const rejectMark = async (mid, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.delete(`${prefix}/${mid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('reject mark error:', error)
        throw error
    }
}