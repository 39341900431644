import {reissue} from "./adminApi";

// 토큰 재발행 로직 담당
export const withTokenReissue = async (apiCall, accessToken, refreshToken, reissueCallback, expirationCallback) => {
    try {
        // 실행하고자 하는 API 호출
        const res = await apiCall(accessToken)
        // 호출 결과 반환
        return res
    } catch (error) { // 호출 중 에러 발생 시
        // 상태 코드가 498이면(토큰 만료 에러 코드)
        if(error.response && error.response.data.status === 498) {
            try {
                // 토큰 재발행 호출
                const reissueData = await reissue(refreshToken)
                console.log('reissue data:', reissueData)
                // 헤더값 추출
                const headers = reissueData.headers

                console.log('headers:', headers)
                // 엑세스 토큰, 리프레시 토큰, 역할, 이름 쿠키 저장
                reissueCallback(headers['authorization'], headers['refresh'], headers['role'], reissueData.data['name'])
                // 실행하고자 하는 API 재호출
                return await apiCall(headers['authorization'])
            } catch (reissueError) { // 재발행 혹은 API 재호출시 에러 발생하면
                console.log('reissueError:', reissueError)
                // 쿠키 지우고 로그인 페이지로 이동
                expirationCallback()
                // 에러 던짐
                throw reissueError
            }
        } else { // 498 에러가 아니면(토큰 관련 에러가 아니면)
            // 에러 던지기
            console.log('withTokenReissue error:', error)
            throw error
        }
    }
}