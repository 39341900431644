import axios from "axios";
import {withTokenReissue} from "./withTokenReissue";

const prefix = `${process.env.REACT_APP_API_SERVER_HOST}/admin-members`

console.log('admin prefix:', prefix)

// 관리자 회원가입
export const register = async (registerObj) => {
    const res = await axios.post(`${prefix}/registration`, registerObj)

    return res.data
}

// 관리자 로그인
export const login = async (loginObj) => {
    const res = await axios.post(`${prefix}/login`, loginObj, {
    // const res = await axios.post(`${API_SERVER_HOST}/admin/admins/login`, loginObj, {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    })
    console.log('headers:', res.headers)
    console.log('test:', res.headers['test'])

    return res
}

// 관리자 로그아웃
export const logout = async (tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager

    try {
        return withTokenReissue(
            (token) => axios.post(`${prefix}/logout`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('Logout error:', error)
        throw error
    }
}

// 토큰 재발행
export const reissue = async (refresh) => {
    try {
        const res = await axios.post(`${prefix}/reissue`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Refresh': `${refresh}`
            }
        })
        return res
    } catch(error) {
        console.log('reissue error:', error)
        throw error
    }
}

// 관리자 전체 조회
export const getList = async (searchTerm, searchBy, filterStatus, direction, page, size, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.get(`${prefix}`, {
                params: {
                    searchTerm: searchTerm,
                    searchBy: searchBy,
                    filterStatus: filterStatus,
                    direction: direction,
                    page: page,
                    size: size
                },
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('admin getList error:', error)
        throw error
    }
}

// 관리자 가입 승인
export const approveAdmin = async (aid, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    return withTokenReissue(
        (token) => axios.patch(`${prefix}/approval/${aid}`, {}, {
            headers: {
                'Content-Type':'application/json',
                'Authorization': `${token}`
            }
        }),
        accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
    )
}

// 관리자 삭제
export const approveDelete = async (aid, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    return withTokenReissue(
        (token) => axios.delete(`${prefix}/approval/${aid}`, {
            headers: {
                'Content-Type':'application/json',
                'Authorization': `${token}`
            }
        }),
        accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
    )
}