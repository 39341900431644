import React, { useEffect, useState } from 'react';
import { updateMember } from '../../api/memberApi';
import {useNavigate} from "react-router-dom";
import {useTokenManager} from "../../api/useTokenManager";

function EditMember(props) {
    const navigator = useNavigate()
    const tokenManager = useTokenManager()
    const query = new URLSearchParams(window.location.search);
    const [member, setMember] = useState({
        mid: query.get('mid') || 0,
        name: query.get('name') || '',
        phoneNumber: query.get('phoneNumber') || '',
        postCode: query.get('postCode') || '',
        address: query.get('address') || '',
        detailAddress: query.get('detailAddress') || '',
        extraAddress: query.get('extraAddress') || '',
        memberType: query.get('memberType') || '',
        email: query.get('email') || '',
    })
    
    const handleChange = (path) => (e) => {
        const {value} = e.target
        setMember((prevMember) => {
            const newState = {...prevMember}
            let current = newState
            const keys = path.split('.')
            keys.slice(0, -1).forEach(key => {
                current = current[key]
            });
            current[keys[keys.length - 1]] = value
            return newState
        })
    };

    const handleClickChange = () => {
        updateMember(member, tokenManager).then(data => {
            console.log(data)
            // if(data === null) {
            //     // TODO: 예외 처리
            // } else {
            //     window.opener.postMessage({
            //         type: 'updateResult',
            //         result: 'Updated Successfully'
            //     }, window.location.origin)
            //     window.close()
            // }
            window.opener.postMessage({
                type: 'updateResult',
                result: 'Updated Successfully'
            }, window.location.origin)
            window.close()
        }).catch(error => {
            let message = null
            let code = null
            if(error.response.status === 401) {
                message = error.message
                code = error.response.status
            } else if(error.response.data) {
                message = error.response.data.error
                code = error.response.data.status
            } else {
                message = error.message
                code = undefined
            }

            navigator('/error', {
                state: {message, code}
            })
        })
    }

    const handleClickJuso = () => {
        window.open(`/juso-popup`, 'pop', 'width=700,height=510, scrollbars=yes, resizable=yes');
    };

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin === window.location.origin) {
                const addressData = event.data;
                setMember((prevMember) => ({
                    ...prevMember,
                    // address: addressData.roadAddrPart1,
                    postCode: addressData.zipNo || member.postCode,
                    address: addressData.roadAddrPart1 || member.address,
                    extraAddress: addressData.roadAddrPart2 || member.extraAddress,
                    // ${addressData.addrDetail}
                }));
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Updated Member Data:', member);
        // 여기서 서버로 업데이트된 데이터를 전송하는 API 호출을 추가할 수 있습니다.
        window.close(); // 수정 완료 후 창 닫기
    };

    console.log('member:',member)
    console.log('memberType:', member.memberType)

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <h2 className="text-xl font-bold mb-4">회원 정보 수정</h2>
                <div className="mb-4">
                    <label className="block text-gray-700">이름</label>
                    <input
                    type="text"
                    name="memberName"
                    value={member.name}
                    onChange={handleChange('name')}
                    className="border rounded px-2 py-1 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">회원 유형</label>
                    <select
                        name="memberType"
                        value={member.memberType}
                        onChange={handleChange('memberType')}
                        className="border rounded px-2 py-1 w-full">
                            <option value="일반">일반</option>
                            <option value="일반의">일반의</option>
                            <option value="전문의">전문의</option>
                            <option value="개인 판매업자">개인 판매업자</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">전화번호</label>
                    <input
                    type="text"
                    name="phoneNumber"
                    value={member.phoneNumber}
                    onChange={handleChange('phoneNumber')}
                    className="border rounded px-2 py-1 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">이메일</label>
                    <input
                    type="email"
                    name="email"
                    value={member.email}
                    onChange={handleChange('email')}
                    className="border rounded px-2 py-1 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">주소</label>
                    <div className='space-y-2'>
                    <input
                            type="text"
                            name="postCode"
                            value={member.postCode}
                            onChange={handleChange('postCode')}
                            className="border rounded px-2 py-1 w-full"
                            placeholder="우편번호"
                            disabled
                        />
                        <div className="flex space-x-2">
                            <input
                                type="text"
                                name="address"
                                value={member.address}
                                onChange={handleChange('address')}
                                className="border rounded px-2 py-1 w-full"
                                placeholder="주소"
                                disabled
                            />
                            <input
                                type="text"
                                name="extraAddress"
                                value={member.extraAddress}
                                onChange={handleChange('extraAddress')}
                                className="border rounded px-2 py-1 w-1/4"
                                placeholder="참고항목"
                                disabled
                            />
                        </div>
                        <input
                            type="text"
                            name="detailAddress"
                            value={member.detailAddress}
                            onChange={handleChange('detailAddress')}
                            className="border rounded px-2 py-1 w-full"
                            placeholder="상세주소"
                        />
                    </div>
                    <button onClick={handleClickJuso} className="bg-blue-500 text-white px-4 py-2 rounded mt-2">
                        주소 변경
                    </button>
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                    type="button"
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                    onClick={() => window.close()}
                    >
                        취소
                    </button>
                    <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={handleClickChange}
                    >
                        저장
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditMember;