import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";

// 토큰 재발행 이후 관련 처리를 담당
export const useTokenManager = () => {
    // React-Cookie를 통해 쿠키를 다루기 위해 필요한 정보
    //  - cookies : 쿠키
    //  - setCookie : 쿠키에 값 할당/쿠키 추가
    //  - removeCookie : 쿠키 삭제
    const [cookies, setCookie, removeCookie] = useCookies()
    const navigator = useNavigate()

    // 토큰 재발행시 토큰 갱신하는 함수
    //  - JWT의 엑세스 토큰, 리프레시 토큰
    //  - 역할 : 어드민인지 유저인지에 따라 권한이 다름
    //  - 이름 : 사이드바 상단에 이름 표시
    const handleReissueToken = (accessToken, refreshToken, role, name) => {
        setCookie("accessToken", accessToken, {
            path: "/",
            sameSite: "None",
            maxAge: 3600 * 3,
            secure: true,
        })
        setCookie("refreshToken", refreshToken, {
            path: "/",
            sameSite: "None",
            maxAge: 3600 * 3,
            secure: true,
        })
        setCookie("role", role, {
            path: "/",
            sameSite: "None",
            maxAge: 3600 * 3,
            secure: true,
        })
        setCookie("name", name, {
            path: "/",
            sameSite: "None",
            maxAge: 3600 * 3,
            secure: true,
        })
    }

    // 토큰 재발행에 실패했을 때 실행할 로직
    //  - 쿠키를 지우고 로그인 페이지로 이동
    const handleExpiredRefresh = () => {
        removeCookie("accessToken")
        removeCookie("refreshToken")
        removeCookie("role")
        removeCookie("name")
        navigator("/")
    }

    // 토큰값, 재발행 성공/실패시 로직 반환
    //  - 다른 위치에서 이를 구조분해 할당으로 가져다 쓸 수 있도록 반환
    return {
        accessToken: cookies['accessToken'],
        refreshToken: cookies['refreshToken'],
        handleReissueToken,
        handleExpiredRefresh
    }
}